




































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import ConsentSettingsService from '@/services/customer/ConsentSettingsService'
import ConsentSettings from '@/models/customer/ConsentSettings'

@Component
export default class Edit extends Vue {
  private isSaving = false
  private data: ConsentSettings = null
  private pageTitle = 'Consent setting'
  private consentType = ''
  private original: ConsentSettings = null
  private rules = {}
  private error = ''

  public created(): void {
    this.data = new ConsentSettings()
    this.original = new ConsentSettings()
    this.rules = {
      name: [(v) => !!v || this.$t('c:validation:This field is required')],
    }
    switch (parseInt(this.$route.params.consentType)) {
      case 1:
        this.consentType = 'email'
        break
      case 2:
        this.consentType = 'SMS'
        break
      default:
        this.$router.back()
    }
    this.load()
  }

  private get id(): number {
    return this.$route.params.id ? parseInt(this.$route.params.id) : null
  }

  private get isNew() {
    return !!this.$route.meta.isNew
  }

  private load() {
    if (!this.isNew) {
      ConsentSettingsService.loadOne(this.id)
        .then((ConsentSettings) => {
          if (!ConsentSettings.id) {
            this.$router.back()
            return
          }
          this.data = ConsentSettings
          this.original = ConsentSettings.clone()
          this.pageTitle = `Edit ${this.consentType} consent`
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    } else {
      this.pageTitle = `New ${this.consentType} consent`
      this.data.consentType = parseInt(this.$route.params.consentType)
    }
  }

  private get form() {
    return this.$refs.form as Vue & { validate: () => boolean }
  }

  private clickSave() {
    if (!this.form.validate()) {
      return
    }
    this.isSaving = true
    if (this.isNew) {
      ConsentSettingsService.create(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          this.isSaving = false
          vxm.alert.onAxiosError(err, 'Could not save consent setting')
        })
    } else {
      this.data.id = this.id
      ConsentSettingsService.update(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          this.isSaving = false
          vxm.alert.onAxiosError(err, 'Could not save consent setting')
        })
    }
  }

  private clickCancel() {
    this.$router.back()
  }
}
