import { axios } from '@/plugins/vueaxios'
import ConsentSettings from '@/models/customer/ConsentSettings'

export default class ConsentSettingsService {
  public static loadOne(id: number): Promise<ConsentSettings> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/consent/settings/' + id)
        .then((response) => {
          resolve(new ConsentSettings(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static create(data: ConsentSettings): Promise<ConsentSettings> {
    return new Promise((resolve, reject) => {
      axios
        .post('/v4/site/consent/settings', data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static update(data: ConsentSettings): Promise<ConsentSettings> {
    return new Promise((resolve, reject) => {
      axios
        .put('/v4/site/consent/settings/' + data.id, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
